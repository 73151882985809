import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { useRouter } from "vue-router";
import { isLogin } from '@/util/EnvUtil';
import { ref } from "vue";
export default {
  setup: function setup() {
    var router = useRouter();
    var loginState = ref(isLogin());

    function handlerApiDoc() {
      // router.push("/swagger/index.html");
      window.open("/swagger/index.html", "_self"); // window.open("/swagger/index.html", "_blank");
    }

    function handlerLoginOut() {
      localStorage.removeItem("userInfo");
      loginState.value = false;
    }

    function handlerAdmin() {
      router.replace("/admin");
    }

    function handlerLogin() {
      router.push("/login");
    }

    var handlerRegister = function handlerRegister() {
      router.push("/register");
    };

    return {
      handlerAdmin: handlerAdmin,
      handlerLoginOut: handlerLoginOut,
      handlerApiDoc: handlerApiDoc,
      handlerLogin: handlerLogin,
      handlerRegister: handlerRegister,
      loginState: loginState
    };
  }
};