import "core-js/modules/es.json.stringify.js";
import { ref, unref, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
export default {
  name: "Login",
  setup: function setup() {
    var _getCurrentInstance = getCurrentInstance(),
        ctx = _getCurrentInstance.ctx,
        proxy = _getCurrentInstance.proxy;

    var router = useRouter();
    var loginRef = ref();
    var loginForm = ref({
      account: "",
      password: ""
    });
    var rules = ref({
      account: [{
        required: true,
        message: "账号不能为空...",
        trigger: "blur"
      }, {
        type: "email",
        min: 5,
        max: 30,
        message: "账号必须是5-30位邮箱格式...",
        trigger: ["blur", "change"]
      }],
      password: [{
        type: "string",
        required: true,
        message: "密码不能为空且只允许数字字母组合...",
        pattern: /^[0-9A-Za-z]+$/,
        trigger: ["blur", "change"]
      }, {
        min: 6,
        max: 18,
        message: "密码必须是6-18位...",
        trigger: ["blur", "change"]
      }]
    });

    var submitForm = function submitForm() {
      var form = unref(loginRef);
      form.validate(function (valid) {
        if (valid) {
          proxy.$axios.post("/api/login", loginForm.value).then(function (res) {
            ElMessage({
              message: "登录成功!",
              type: "success"
            });
            localStorage.setItem("userInfo", JSON.stringify(res.result));
            localStorage.setItem("token", res.result.token);
            router.push("/admin");
          });
        } else {
          ElMessage.error("参数校验错误.");
          return false;
        }
      }).catch(function () {});
    };

    var resetForm = function resetForm() {
      var form = unref(loginRef);
      form.resetFields();
    };

    return {
      loginRef: loginRef,
      loginForm: loginForm,
      rules: rules,
      submitForm: submitForm,
      resetForm: resetForm
    };
  }
};