import _objectSpread from "/Users/peakchao/Code/Web/apiopen-v2/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { defineComponent, defineAsyncComponent, ref, unref, toRefs, reactive, onMounted, onUnmounted, getCurrentInstance } from "vue";
import { useStore, mapGetters } from "vuex";
export default defineComponent({
  name: "Home",
  components: {
    HomeTitle: defineAsyncComponent(function () {
      return import("@/components/HomeTitle.vue");
    }),
    Notice: defineAsyncComponent(function () {
      return import("@/components/Notice.vue");
    })
  },
  setup: function setup() {
    var store = useStore();

    var add = function add() {
      // store.commit("increment");
      // store.state.count++;
      store.dispatch("addCount");
    };

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy;

    var data = reactive({
      isPlay: false,
      miniVideoList: [],
      videoItem: {}
    });
    var playIndex = 0;
    var timer;
    var showRemark = ref(false);
    var showPlay = ref(true);
    var videoRef = ref();

    var videoFun = function videoFun() {
      var video = unref(videoRef);
      video.paused ? video.play() : video.pause();
    };

    var time;

    var move = function move() {
      showRemark.value = true;
      time && clearTimeout(time);
      time = setTimeout(function () {
        showRemark.value = false;
      }, 2000);
    };

    var keyEvent = function keyEvent(e) {
      var video = unref(videoRef);
      timer && clearTimeout(timer);

      if (e.keyCode == 38) {
        playIndex--;

        if (playIndex >= 0) {
          data.videoItem = data.miniVideoList[playIndex];
          timer = setTimeout(function () {
            video.play();
          }, 200);
        } else if (playIndex < 0) {
          playIndex = 0;
        }
      } else if (e.keyCode == 40) {
        playIndex++;

        if (playIndex >= data.miniVideoList.length) {
          playIndex = 0;
          getMiniVideo();
        } else {
          data.videoItem = data.miniVideoList[playIndex];
          timer = setTimeout(function () {
            video.play();
          }, 200);
        }
      } else if (e.keyCode == 32) {
        video.paused ? video.play() : video.pause();
      }
    };

    var getMiniVideo = function getMiniVideo() {
      var video = unref(videoRef);
      proxy.$axios.get("/api/getMiniVideo").then(function (res) {
        data.miniVideoList = res.result.list;
        data.videoItem = data.miniVideoList[0];
        timer && clearTimeout(timer);
        timer = setTimeout(function () {
          video.play();
        }, 200);
      });
    };

    var play = function play() {
      // data.isPlay = true;
      showPlay.value = false;
    };

    var pause = function pause() {
      // data.isPlay = false;
      showPlay.value = true;
    };

    onMounted(function () {
      getMiniVideo();
      document.addEventListener("keydown", keyEvent);
      var video = unref(videoRef);
      video && video.addEventListener("play", play);
      video && video.addEventListener("pause", pause);
    });
    onUnmounted(function () {
      document.removeEventListener("keydown", keyEvent);
      var video = unref(videoRef);
      video && video.removeEventListener("play", play);
      video && video.removeEventListener("pause", pause);
    });
    return _objectSpread(_objectSpread({}, toRefs(data)), {}, {
      showRemark: showRemark,
      showPlay: showPlay,
      videoRef: videoRef,
      move: move,
      videoFun: videoFun,
      add: add
    }, mapGetters(["getCount"]));
  }
});